exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-opt-build-repo-content-pages-about-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/opt/build/repo/content/pages/about/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-opt-build-repo-content-pages-about-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-opt-build-repo-content-pages-contact-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/opt/build/repo/content/pages/contact/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-opt-build-repo-content-pages-contact-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-advertising-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/advertising/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-advertising-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-content-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/content/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-content-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-e-commerce-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/e-commerce/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-e-commerce-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-leadership-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/leadership/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-leadership-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-project-management-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/project management/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-project-management-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-seo-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/seo/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-seo-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-web-design-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/web-design/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-opt-build-repo-content-projects-web-design-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

